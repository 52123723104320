import React from 'react';
import { Meta } from '@hxm/components/meta/Meta';
import {
  frontpage,
  frontpageMessages,
} from '@hxm/contentful/messages/messages.frontpage';
import { GetServerSidePropsWithMessages } from '@hxm/contentful/types/page';
import { useMessages } from '@hxm/hooks/useMessages';
import { InferGetServerSidePropsType } from 'next';

import { FrontpageContainer } from 'containers/pages/frontpage/Frontpage';

import { namespaceQuery } from 'utils/namespaceQuery';

export type PageProps = {
  image: string | null;
  telAuth: boolean;
};

export default function PageComponent(
  data: InferGetServerSidePropsType<typeof getServerSideProps>
) {
  const { formatMessage } = useMessages();

  return (
    <>
      <Meta
        title={formatMessage(frontpageMessages.pageTitle)}
        description={formatMessage(frontpageMessages.title)}
        image={data.image ? `${data.image}?w=1200` : undefined}
      />

      <FrontpageContainer frontpage={data} />
    </>
  );
}

export const getServerSideProps: GetServerSidePropsWithMessages<PageProps> = async ({
  locale,
}) => {
  const { messages, image, settings } = await namespaceQuery(frontpage, locale);
  const telAuth = process.env.TEL_AUTHENTICATION !== 'false';

  return {
    props: {
      messages,
      image,
      settings,
      telAuth,
    },
  };
};
