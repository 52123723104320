import React from 'react';
import classnames from 'classnames/bind';

import s from './FlexCol.module.scss';

const c = classnames.bind(s);

type IProps = {
  width?: 1 | 2 | 3;
  children: React.ReactNode;
};

export const FlexCol = ({ width = 1, children }: IProps) => {
  return (
    <div
      className={c(s.flexCol, {
        [s.flexCol1]: width === 1,
        [s.flexCol2]: width === 2,
        [s.flexCol3]: width === 3,
      })}
    >
      {children}
    </div>
  );
};
