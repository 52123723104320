import { Children } from 'react';
import classnames from 'classnames/bind';

import { Flex } from '../flex/Flex';
import { FlexCol } from '../flex/FlexCol';
import { H3 } from '../heading/Heading';
import { Section } from '../section/Section';

import s from './FormLayout.module.scss';

const c = classnames.bind(s);

type IProps = {
  children?: React.ReactNode;
  wide?: boolean;
};

export const FormLayoutItem = ({ children, wide }: IProps) => (
  <div
    className={c(
      s.formLayout__item,
      {
        wide,
      },
      s.formLayout__fields
    )}
  >
    {children}
  </div>
);

export const FormLayout = ({
  title,
  description,
  extraInfo,
  children,
}: {
  title?: string;
  description?: string;
  extraInfo?: string;
  children: React.ReactNode;
}) => {
  const childArray = Children.toArray(children).filter(Boolean);

  if (!childArray.length) {
    return null;
  }

  return (
    <>
      {title && (
        <H3 as="h2" className={s.formLayout__title}>
          {title}
        </H3>
      )}
      <div className={s.formLayout}>
        {description && (
          <Section container>
            <p>{description}</p>
            <br />
            <br />
          </Section>
        )}
        <Section>
          <Flex>
            {extraInfo && <FlexCol width={1}>{extraInfo}</FlexCol>}
            <FlexCol width={3}>
              {childArray.map((child, i) => (
                <FormLayoutItem key={i}>{child}</FormLayoutItem>
              ))}
            </FlexCol>
          </Flex>
        </Section>
      </div>
    </>
  );
};
