import { Step2Result } from '@hugsmidjan-is/audkenni';
import { clientFetch } from '@hxm/utils/clientFetch';
import * as Sentry from '@sentry/nextjs';

import { AudkenniStatus, AuthJob, AuthResult, AuthStatus } from 'types/iron';

const successRedirect = process.env.NEXT_PUBLIC_AUTH_REDIRECT_SUCCESS;

/**
 *
 * @param id authentication job id received from `/auth/login`
 * @param interval time between polling calls
 * @param maxPoll maximum number of polling calls
 */
export const authStatusPoll = (id: AuthJob['id'], interval = 5000, maxPoll = 18) => {
  const poll = 1;
  return new Promise<AuthResult>((resolve, reject) => {
    const statusPing = setInterval(() => {
      if (poll < maxPoll) {
        clientFetch<AuthStatus>(`/api/auth/login/${id}`)
          .then((response) => {
            if (response?.failed) {
              clearInterval(statusPing);
              resolve({
                success: false,
              });
            }

            if (response?.delayed) {
              clearInterval(statusPing);
              resolve({
                success: false,
                message: 'Too many requests, try again in 90 seconds',
              });
            }

            if (response?.complete && !response.failed) {
              clearInterval(statusPing);
              resolve({
                success: true,
                redirect: successRedirect ?? '/',
              });
            }
          })
          .catch(Sentry.captureException);
      } else {
        clearInterval(statusPing);
        reject('max tries reached');
      }
    }, interval);
  });
};

export const AudkenniStatusPoll = (data: Step2Result, interval = 3000, maxPoll = 10) => {
  return new Promise<AuthResult>((resolve, reject) => {
    let poll = 1;
    const statusPing = setInterval(() => {
      if (poll <= maxPoll) {
        clientFetch<AudkenniStatus>(`/api/audkenni/checkAuthentication`, {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response?.failed) {
              clearInterval(statusPing);
              resolve({
                message: response.reason,
                success: false,
              });
            }

            if (response?.complete && !response.failed) {
              clearInterval(statusPing);
              resolve({
                success: true,
                redirect: successRedirect ?? '/',
              });
            }
          })
          .catch(Sentry.captureException);
      } else {
        clearInterval(statusPing);
        reject({
          success: false,
          message: 'Aðgerð rann út á tíma',
        });
      }
      poll++;
    }, interval);
  });
};
