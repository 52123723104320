import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button } from '@hxm/components/button/Button';
import { FormLayout } from '@hxm/components/form-elements/FormLayout';
import { SsnInput } from '@hxm/components/form-elements/SsnInput';
import { TelephoneInput } from '@hxm/components/form-elements/TelephoneInput';
import { H1, H4 } from '@hxm/components/heading/Heading';
import { Login } from '@hxm/components/login/Login';
import { LoginTabs } from '@hxm/components/login/LoginTabs';
import Modal from '@hxm/components/modal/Modal';
import { Picture } from '@hxm/components/picture/Picture';
import { frontpageMessages } from '@hxm/contentful/messages/messages.frontpage';
import { useMessages } from '@hxm/hooks/useMessages';
import { useSettings } from '@hxm/hooks/useSettings';
import { clientFetch, swrFetcher } from '@hxm/utils/clientFetch';
import { logger } from '@hxm/utils/logger';
import * as Sentry from '@sentry/nextjs';
import { Locale } from 'contentful-locale';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { PageProps } from 'pages';
import { AudkenniJob, AuthJob, AuthResult } from 'types/iron';
import { AudkenniStatusPoll, authStatusPoll } from 'utils/authStatusPoll';

const testUsers = process.env.NEXT_PUBLIC_TEST_USERS?.split(';');
const isDev = process.env.NODE_ENV === 'development';

// eslint-disable-next-line complexity
export const FrontpageContainer = ({ frontpage }: { frontpage: PageProps }) => {
  const [tab, setTab] = useState(isDev && testUsers ? 0 : 1);
  const { formatMessage } = useMessages();
  const [loginTelephone, setLoginTelephone] = useState('');
  const [authInProgress, setAuthInProgress] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [authFailedReason, setAuthFailedReason] = useState('');
  const [modalActive, setModalActive] = useState(false);
  const router = useRouter();
  const { data, error } = useSWR<Array<Locale>>('/api/locales/', swrFetcher);
  const { settings } = useSettings();
  const { telAuth } = frontpage;
  const { publicRuntimeConfig } = getConfig();
  const { version } = publicRuntimeConfig ?? 0;

  useEffect(() => {
    const defaultTab = !telAuth && settings?.islykill && settings.islandIsUrl ? 2 : 1;
    setTab(isDev && testUsers ? 0 : defaultTab);
  }, [settings, setTab, telAuth]);

  const onTelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLoginTelephone(e.target.value);
  }, []);

  const loginTabs: Array<{ id: number; title: string }> = [];

  if (telAuth) {
    loginTabs.push({
      id: 1,
      title: formatMessage(frontpageMessages.telAuthTitle) ?? 'Rafræn skilríki',
    });
  }

  if (settings?.islykill && settings.islandIsUrl) {
    loginTabs.push({
      id: 2,
      title: formatMessage(frontpageMessages.islykillAuthTitle) ?? 'island.is',
    });
  }

  if (settings?.kortaSkilriki && settings.islandIsUrl) {
    loginTabs.push({
      id: 3,
      title: formatMessage(frontpageMessages.cardAuthTitle) ?? 'Skilríki á korti',
    });
  }
  // TODO: Add audkenni to settings
  if (process.env.NEXT_PUBLIC_USE_AUDKENNI === 'true') {
    loginTabs.push({
      id: 4,
      title: formatMessage(frontpageMessages.appAuthTitle) ?? 'Auðkenning í appi',
    });
  }

  if (isDev && testUsers) {
    loginTabs.unshift({
      id: 0,
      title: 'Fake login',
    });
  }

  const locales = !data && !error ? undefined : data?.map((locale) => locale.code);

  return (
    <Login
      locales={locales}
      selectedLocale={router.locale}
      image={<Picture src={frontpage.image ?? ''} />}
    >
      <H1>{formatMessage(frontpageMessages.title)}</H1>

      <LoginTabs tabs={loginTabs} setTab={setTab} active={tab} />

      {isDev && testUsers && tab === 0 && (
        <FormLayout>
          {testUsers.map((kt, i) => (
            <Button
              key={i}
              loading={authInProgress}
              onClick={() => {
                if (!authInProgress) {
                  setAuthInProgress(true);
                  clientFetch<AuthResult>('/api/auth/impersonate', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ssn: kt }),
                  })
                    .then((res) => {
                      setAuthInProgress(false);
                      if (res?.success) {
                        router.push(res.redirect).catch(Sentry.captureException);
                      }
                    })
                    .catch(Sentry.captureException);
                }
              }}
            >
              Login user {kt}
            </Button>
          ))}
        </FormLayout>
      )}

      {tab === 1 && (
        <FormLayout>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!authInProgress) {
                setAuthInProgress(true);
                clientFetch<AuthJob>('/api/auth/login', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ tel: loginTelephone }),
                })
                  .then((response) => {
                    if (response) {
                      authStatusPoll(response.id)
                        .then((r) => {
                          setAuthInProgress(false);
                          if (r.success) {
                            router.push(r.redirect).catch(Sentry.captureException);
                          } else {
                            setModalActive(true);
                          }
                        })
                        .catch(Sentry.captureException);
                    }
                  })
                  .catch((e) => {
                    Sentry.captureException(e);
                    setAuthInProgress(false);
                    logger.error('authentication failed', { exception: e as Error });
                  });
              }
            }}
          >
            <TelephoneInput
              login
              autoFocus
              label={formatMessage(frontpageMessages.telAuthPlaceholder)}
              name="tel"
              value={loginTelephone}
              onChange={onTelChange}
              placeholder={formatMessage(frontpageMessages.telAuthPlaceholder)}
            />

            <Button
              type="submit"
              size="large"
              loading={authInProgress}
              disabled={loginTelephone.length !== 7 || authInProgress}
            >
              {formatMessage(frontpageMessages.telAuthButton)}
            </Button>
          </form>
        </FormLayout>
      )}

      {tab === 2 && settings?.islandIsUrl && (
        <FormLayout>
          <Button size="large" to={settings.islandIsUrl}>
            {formatMessage(frontpageMessages.islykillAuthButton)}
          </Button>
        </FormLayout>
      )}

      {tab === 3 && settings?.islandIsUrl && (
        <FormLayout>
          <Button size="large" to={settings.islandIsUrl}>
            {formatMessage(frontpageMessages.cardAuthButton)}
          </Button>
        </FormLayout>
      )}
      {tab === 4 && (
        <FormLayout>
          <FormLayout>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!authInProgress) {
                  setAuthInProgress(true);
                  clientFetch<AudkenniJob>('/api/audkenni/initiateAuth', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ nationalRegistry: loginTelephone }),
                  })
                    .then((response) => {
                      if (response) {
                        setVerificationCode(response.data.verificationCode);
                        AudkenniStatusPoll(response.data)
                          .then((r) => {
                            setAuthInProgress(false);
                            setVerificationCode('');
                            if (r.success) {
                              router.push(r.redirect).catch(Sentry.captureException);
                            } else {
                              if (r.message) {
                                setAuthFailedReason(r.message);
                              }
                              setModalActive(true);
                            }
                          })
                          .catch((e: Error) => {
                            setAuthInProgress(false);
                            Sentry.captureException(e);
                            if (e.message) {
                              setAuthFailedReason(e.message);
                            }
                            setModalActive(true);
                          });
                      }
                    })
                    .catch((e) => {
                      setAuthInProgress(false);
                      logger.error('authentication failed', { exception: e as Error });
                    });
                }
              }}
            >
              <SsnInput
                login
                autoFocus
                label={formatMessage(frontpageMessages.appAuthPlaceholder)}
                name="ssn"
                value={loginTelephone}
                onChange={onTelChange}
                placeholder={formatMessage(frontpageMessages.appAuthPlaceholder)}
              />
              {!!verificationCode && (
                <H4 style={{ paddingTop: '2em' }}>
                  {formatMessage(frontpageMessages.appAuthVerificationCode)}{' '}
                  {verificationCode}
                </H4>
              )}

              <Button
                type="submit"
                size="large"
                loading={authInProgress}
                disabled={loginTelephone.length !== 10 || authInProgress}
              >
                {formatMessage(frontpageMessages.appAuthButton)}
              </Button>
            </form>
          </FormLayout>
        </FormLayout>
      )}

      {modalActive && (
        <Modal
          startOpen={false}
          onClosed={() => {
            setAuthFailedReason('');
            setModalActive(false);
          }}
          portal={false}
        >
          <H1>{formatMessage(frontpageMessages.failedAuthTitle)}</H1>
          <p>{authFailedReason || formatMessage(frontpageMessages.failedAuthText)}</p>
        </Modal>
      )}
      <div style={{ display: 'none' }}>v{version}</div>
    </Login>
  );
};
