type SvgProps = {
  className?: string;
};
export const Search = ({ className }: SvgProps) => (
  <svg
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.46992 8.63464L12 11.1641L11.1641 12L8.63464 9.46992C7.69346 10.2244 6.52279 10.6348 5.31653 10.6331C2.38181 10.6331 0 8.25126 0 5.31653C0 2.38181 2.38181 0 5.31653 0C8.25126 0 10.6331 2.38181 10.6331 5.31653C10.6348 6.52279 10.2244 7.69346 9.46992 8.63464ZM8.28493 8.19632C9.03463 7.42535 9.4533 6.39191 9.45161 5.31653C9.45161 3.0316 7.60087 1.18145 5.31653 1.18145C3.0316 1.18145 1.18145 3.0316 1.18145 5.31653C1.18145 7.60087 3.0316 9.45161 5.31653 9.45161C6.39191 9.4533 7.42535 9.03463 8.19632 8.28493L8.28493 8.19632Z"
      fill="currentColor"
    />
  </svg>
);
