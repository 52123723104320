type SvgProps = {
  className?: string;
};

export const Information = ({ className }: SvgProps) => (
  <svg
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM12.7275 7.00004C12.7275 10.1631 10.1633 12.7273 7.00022 12.7273C3.83714 12.7273 1.27295 10.1631 1.27295 7.00004C1.27295 3.83695 3.83714 1.27277 7.00022 1.27277C10.1633 1.27277 12.7275 3.83695 12.7275 7.00004ZM7.63851 8.27168H8.27446V9.54441H5.729V8.27168H6.36537V6.99895H5.729V5.72622H7.63851V8.27168ZM7.63643 4.4535C7.63643 4.80495 7.35142 5.08986 6.99985 5.08986C6.64828 5.08986 6.36328 4.80495 6.36328 4.4535C6.36328 4.10204 6.64828 3.81713 6.99985 3.81713C7.35142 3.81713 7.63643 4.10204 7.63643 4.4535Z"
      fill="currentColor"
    />
  </svg>
);
