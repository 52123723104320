import type { HTMLElementList } from '@hxm/types/html-types';
import classnames from 'classnames/bind';

import { Container } from '../container/Container';

import s from './Section.module.scss';

const c = classnames.bind(s);

type IProps = {
  children: React.ReactNode;
  container?: boolean;
  as?: HTMLElementList;
  className?: string;
};

export const Section = ({
  children,
  container = false,
  as = 'section',
  className,
}: IProps) => {
  const SectionEl = as;
  const content = container ? <Container>{children}</Container> : children;

  return <SectionEl className={c(s.section, className)}>{content}</SectionEl>;
};
